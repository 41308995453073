import React from 'react'
import banner from "../assets/bannerYT.png";
import evaclean from '../assets/evaclean2.png'
import ecolab from '../assets/ecolab2.png'

export default function Chemicals() {
  return(
    <div style={styles.root}>
      <h1>CHEMICALS</h1>
      <p style={{textAlign: 'center'}}>Our chemicals are EPA and CDC approved to disinfect your facility safely and
        effectively while eliminating harmful viruses.</p>
      <img src={evaclean} style={styles.image}/>
      <img src={ecolab} style={styles.image}/>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#005fa4',
    padding: 20
  },
  image: {
    width: '70vw',
    maxHeight: 400,
    maxWidth: 400,
    objectFit: 'cover',
    margin: 20
  },
}
