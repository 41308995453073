export const CertificationsArray = [
  {
    name: 'Genghis Grill',
    path: '/genghis-grill',
    link: "https://www.youtube.com/embed/ini45sznIv8",
  },
  {
    name: 'Arthritis Relief',
    path: '/arthritis-relief',
    link: "https://www.youtube.com/embed/hz1x3GuWA8A",
  },
  {
    name: 'Arthritis Relief',
    path: '/arthritis-relief-sa',
    link: "https://www.youtube.com/embed/eHHDmMUbkT8",
  },
  {
    name: 'Northrock Church',
    path: '/northrock-church',
    link: "https://www.youtube.com/embed/GlJbf_oQuhs",
  },
  {
    name: 'Baskin Robbins',
    path: '/baskin-robbins-281',
    link: "https://www.youtube.com/embed/7ePct68d0Ug",
  },
  {
    name: 'Baskin Robbins',
    path: '/baskin-robbins-schertz',
    link: "https://www.youtube.com/embed/m-k3ftYH6dM",
  },
  {
    name: 'Baskin Robbins',
    path: '/baskin-robbins-zavala',
    link: "https://www.youtube.com/embed/v9gc8QabUMw",
  },
  {
    name: 'Atrium',
    path: '/atrium',
    link: "https://www.youtube.com/embed/UsuKG54XbJI"
  },
  {
    name: 'San Antonio Masonry and Steel',
    path: '/samasonry',
    link: "https://www.youtube.com/embed/bv8Svn_wo5g"
  },
  {
    name: 'San Antonio Masonry and Steel SA',
    path: '/samasonry-sa',
    link: "https://www.youtube.com/embed/fXZFCPpiebY"
  },
  {
    name: 'Night and Day Pediatrics',
    path: '/nightandday',
    link: "https://www.youtube.com/embed/wGBrF953bTw"
  },
  {
    name: 'Health by Design',
    path: '/healthbydesign',
    link: "https://www.youtube.com/embed/lkFHsrI17bc"
  },
  {
    name: 'Marble Slab Creamery Rivercenter Mall',
    path: '/marbleslab-rivercenter',
    link: "https://www.youtube.com/embed/4ipgp33WGYk"
  },
  {
    name: 'Great American Cookies Rivercenter Mall',
    path: '/greatamericancookies-rivercenter',
    link: "https://www.youtube.com/embed/OJ3JICMwp0I"
  },
  {
    name: 'Marble Slab Creamery La Cantera',
    path: '/marbleslab-lacantera',
    link: "https://www.youtube.com/embed/3PWT8Dawtz4"
  },
  {
    name: 'Great American Cookies La Cantera',
    path: '/greatamericancookies-lacantera',
    link: "https://www.youtube.com/embed/_jSygVkjhh8"
  },
  {
    name: 'Pretzelmaker Northstar Mall',
    path: '/pretzelmaker-northstar',
    link: "https://www.youtube.com/embed/ODXa-ujFVYo"
  },
  {
    name: 'Great American Cookies Northstar Mall',
    path: '/greatamericancookies-northstar',
    link: "https://www.youtube.com/embed/YBQYH9ywLv8"
  },
  {
    name: 'Legoland',
    path: '/legoland',
    link: "https://www.youtube.com/embed/Tp5NTfm3I8o",
  },
  {
    name: 'WB Liquors Pflugerville',
    path: '/wbliquors-pflugerville',
    link: "https://www.youtube.com/embed/ZNSyLI58w6A",
  },
  {
    name: 'South Texas Oncology',
    path: '/south-texas-oncology',
    link: "https://www.youtube.com/embed/h9XhZ0yG8s4",
  },
  {
    name: 'Arthritis Relief Houston, Bellaire',
    path: '/arthritis-relief-bellaire',
    link: "https://www.youtube.com/embed/YsBYAP4Vf4w",
  },
  {
    name: 'Specialty Surgery Center',
    path: '/specialty-surgery-center',
    link: "https://www.youtube.com/embed/PXDkS4yQD3Y",
  },
  {
    name: 'Women Partners',
    path: '/women-partners',
    link: "https://www.youtube.com/embed/WAJ_bZwUihs",
  },
  {
    name: 'Arthritis Relief Clear Lake',
    path: '/arthritis-relief-clear-lake',
    link: "https://www.youtube.com/embed/KQ1JrM_GQCo",
  },
  {
    name: 'RYC Foods',
    path: '/rycfoods',
    link: "https://www.youtube.com/embed/u6Imwuswojo",
  },
  {
    name: 'Alamo Supplies',
    path: '/alamosupplies',
    link: "https://www.youtube.com/embed/5Vze052lxMI",
  },
  {
    name: 'Marble Slab Creamery HW 151',
    path: '/marbleslab-151',
    link: "https://www.youtube.com/embed/VCUth0qhruA"
  },
  {
    name: 'Heart Clinic of San Antonio',
    path: '/heartclinicsa',
    link: "https://www.youtube.com/embed/dUTeqL9hocw"
  },
  {
    name: 'TSAOG Orthopedics',
    path: '/tsaog-orthopedics',
    link: "https://www.youtube.com/embed/A_MMeD8Mc4o"
  },
  {
    name: 'Transplace',
    path: '/transplace',
    link: "https://www.youtube.com/embed/hhCDc2BJnNg"
  },
  {
    name: 'Transplace',
    path: '/transplace-eaglepass',
    link: "https://www.youtube.com/embed/8dKO4hcMIY8"
  },
  {
    name: 'Franklin Park',
    path: '/franklin-park-boerne',
    link: "https://www.youtube.com/embed/moSNYY3d8OI"
  },
  {
    name: 'South Texas Smiles',
    path: '/south-texas-smiles',
    link: "https://www.youtube.com/embed/Mz9nc8Tt-uo"
  },
  {
    name: 'Endeavor Clinical Trials',
    path: '/endeavor',
    link: "https://www.youtube.com/embed/_pFRmJ5ZJU8"
  },
  {
    name: 'TSAOG Orthopedics Ridgewood',
    path: '/tsaog-orthopedics-ridgewood',
    link: "https://www.youtube.com/embed/serleasq0pY"
  }
]
