import React, {useEffect, useState} from 'react';
import './App.css';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import banner from './assets/bannerYT2.png'
import bannersmall from './assets/bannerYT3.png'
import logo from './assets/HM-Logo-Marca Registrada Sin Tagline[7790].png'
import Chemicals from "./components/Chemicals";
import Methods from "./components/Methods";
import Link from "@material-ui/core/Link";
import { HashRouter, Route, Switch } from 'react-router-dom';
import { CertificationsArray } from './CertificationsArray'
import bluecovid from './assets/bluecovid.png'
import spray from './assets/electrostatic-check-w.png'
import uv from './assets/UV-w.png'
import contact from './assets/contact.JPG'
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from '@material-ui/icons/Share';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import atp from '../src/assets/atp-test-w.png'
import {useSpring, animated} from 'react-spring'
import identify from './assets/identify.png'
import educate from './assets/educate.png'
import disinfect from './assets/disinfect.png'
import test from './assets/test.png'
import approve from './assets/approved.png'
import background from './assets/background.png'
import badge from './assets/certified-shield.png'
import bannerTall from './assets/bannertall.png'
import disinfectionText from './assets/disinfection-certificate-text.png'
import god from './assets/god.png'
import expectations from './assets/expectations.png'
import commitment from './assets/commitment.png'
import Faq from "./components/FAQ";
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function App() {
  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [learnMore, setLearnMore] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    window.scrollTo(0, 0)
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  let mobile = width < 800

  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  const leftRight = useSpring({
    left: 0,
    from: { left: -1000 },
  })

  const pages = CertificationsArray.map((place) => (
    <Route exact path={place.path} name={place.name} render={() =>
      <div style={{width: '100%', position: 'relative'}}>
        <iframe width={width > 1000 ? 700 : width} height="350" src={place.link} frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
        </iframe>
        <animated.div style={{...leftRight, position: 'relative', marginLeft: 25, color: 'white'}}>
          <h3>{place.name} <span style={{color: 'white'}}>| Certified Disinfected</span></h3>
        </animated.div>
      </div>
    }
    />
  ))

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color={'white'}>
        <a href={"http://www.hygienemerit.com/contact-us/"} style={{textDecoration: 'none'}}>
          {/*<img src={contact} style={{height: 40, position: 'relative', top: -3}}/>*/}
          {mobile ?
            <div style={{backgroundColor: '#37cefd', width: '100vw', height: 55, overflow: 'hidden', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <PhoneIcon style={{color: 'white', marginLeft: 25, marginRight: 10}} fontSize={'small'}/>
                <text style={{color: 'white', fontFamily: 'Raleway', fontSize: 14}}>(210) 334.4333 : SA</text>
                <PhoneIcon style={{color: 'white', marginLeft: 25, marginRight: 10}} fontSize={'small'}/>
                <text style={{color: 'white', fontFamily: 'Raleway', fontSize: 14}}>(956) 803.1900 : RGV</text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 5}}>
                <MailOutlineIcon style={{color: 'white', marginLeft: 25, marginRight: 10}} fontSize={'small'} />
                <text style={{color: 'white', fontFamily: 'Raleway', fontSize: 14}}>info@hygienemerit.com</text>
              </div>
            </div>
            :
            <div style={{backgroundColor: '#37cefd', width: '100vw', height: 35, overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
              <PhoneIcon style={{color: 'white', marginLeft: 25, marginRight: 10}} fontSize={'small'}/>
              <text style={{color: 'white', fontFamily: 'Raleway', fontSize: 14}}>(210) 334.4333 : San Antonio Metro Area</text>
              <PhoneIcon style={{color: 'white', marginLeft: 25, marginRight: 10}} fontSize={'small'}/>
              <text style={{color: 'white', fontFamily: 'Raleway', fontSize: 14}}>(956) 803.1900 : Rio Grande Valley</text>
              <MailOutlineIcon style={{color: 'white', marginLeft: 25, marginRight: 10}} fontSize={'small'} />
              <text style={{color: 'white', fontFamily: 'Raleway', fontSize: 14}}>info@hygienemerit.com</text>
            </div>
          }
        </a>
        <Toolbar style={{display: 'flex', alignItems: 'center'}}>
          <a href="https://hygienemerit.com">
            <img src={logo} alt={''} style={{height: 40, alignSelf: 'center'}}/>
          </a>
          <div style={{flex: 1}}/>
        </Toolbar>
      </AppBar>

      <div>
        <div className={classes.appBarSpacer}/>
        <div style={{height: mobile ? 55 : 35}}/>
        {mobile ?
          <div className="container" style={{//...styles.banner,
            backgroundImage: mobile ? `url(${bannerTall})` : `url(${background})`, alignItems: 'center',
            justifyContent: 'space-around', display: 'flex', //maxHeight: mobile ? '100vh' : 350,
            //width: width,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            flexDirection: 'column',
            paddingTop: 25
          }}>

            <img src={disinfectionText} style={{width: '90vw'}}/>

            <img src={badge} style={{width: '80%'}}/>

            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
              <text style={{color: 'white', margin: 25, textAlign: 'center',
                fontSize: 20, marginBottom: 50
              }}>
                This facility has been manually cleaned and disinfected with EPA registered hospital
                grade products, following CDC guidelines and in conjunction with touchless infection control devices to help prevent
                the spread of Corona and Flu viruses.
              </text>
            </div>
          </div>
          :
          <div className="container" style={{//...styles.banner,
            backgroundImage: mobile ? `url(${bannerTall})` : `url(${background})`, alignItems: 'center',
            justifyContent: 'space-around', display: 'flex', //maxHeight: mobile ? '100vh' : 350,
            //width: width,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '30vw',
            //minHeight: 300
          }}>

            <div style={{flex: 1, display: 'flex', justifyContent: 'center',}}>
              <img src={disinfectionText} style={{width: '75%', marginTop: 25}}/>
            </div>

            <img src={badge} style={{height: '90%'}}/>

            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
              <text style={{color: 'white', margin: 50, textAlign: 'center',
                fontSize: width > 1200 ? 20 : 15
              }}>
                This facility has been manually cleaned and disinfected with EPA registered hospital
                grade products, following CDC guidelines and in conjunction with touchless infection control devices to help prevent
                the spread of Corona and Flu viruses.
              </text>
            </div>
          </div>
        }
      </div>

      {/* Identify Educate Disinfect */}
      <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
        <h2 style={{color: '#005fa4'}}>Disinfection Control</h2>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div
          style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center',
            margin: '15px 45px 85px 45px', width: mobile ? '100%' : '60%'
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={disinfect} style={{width: '10vw', minWidth: 100, marginTop: 15}}/>
            <text style={{color: '#005fa4', fontSize: 20, marginTop: 15}}>Disinfect</text>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={test} style={{width: '10vw', minWidth: 100, marginTop: 15}}/>
            <text style={{color: '#005fa4', fontSize: 20, marginTop: 15}}>Test</text>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={approve} style={{width: '10vw', minWidth: 100, marginTop: 15}}/>
            <text style={{color: '#005fa4', fontSize: 20, marginTop: 15}}>Approve</text>
          </div>
        </div>
      </div>

      <div style={{display: 'flex', justifyContent: 'center', backgroundColor: '#005fa4'}}>
        <div style={{...styles.background, display: 'flex', flexDirection: width < 1000 ? 'column' : 'row', paddingTop: 0, width: mobile ? '100%' : '75%'}}>
          <div style={{ justifyContent: 'center', display: 'flex', padding: 0, marginTop: 0, marginBottom: 10, flex: 1}}>
            <HashRouter>
              <React.Suspense>
                <Switch>
                  {pages}
                </Switch>
              </React.Suspense>
            </HashRouter>
          </div>

          <animated.div
            style={{...fade, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 15, flex: 1}}>
            <img src={spray} style={{width: '90vw', maxWidth: 500}}/>
            <img src={uv} style={{width: '90vw', maxWidth: 500}}/>
            <img src={atp} style={{width: '90vw', maxWidth: 500}}/>

            <div
              className={'expandHover'}
              style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', margin: '15px 0px 15px'}}
              onClick={() => {setLearnMore(!learnMore)}}
            >
              <text className={'noText'} style={{color: 'white', marginRight: 15}}>More Details</text>
              <div
                style={
                  { backgroundColor: 'white',
                    width: 20, height: 20, borderRadius: 30, display: 'flex', justifyContent: 'center',
                    alignItems: 'center'
                  }}
              >
                {learnMore ?
                  <ExpandLessIcon style={{margin: '0px 15px 0px 15px', color: '#005fa4'}} fontSize={'small'}/> :
                  <ExpandMoreIcon style={{margin: '0px 15px 0px 15px', color: '#005fa4'}} fontSize={'small'}/>
                }
              </div>
            </div>
          </animated.div>
        </div>
      </div>

      { learnMore ?
        <div>
          <div style={styles.banner3}>
            <h1 style={{textAlign: 'center'}}>Virucide Disinfection</h1>
          </div>
          <Methods mobile={mobile}/>
          <Chemicals />
        </div>
        : null
      }

      {/* About Us */}
      <div style={{margin: '25px 0px 50px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 50}}>

        { mobile ?
          <div style={{margin: 25, display: 'flex', flexDirection: 'column', width: '90%'}}>
            <h2 style={{color: '#005fa4'}}>About Us</h2>
            <text style={{fontSize: 20, textAlign: mobile ? 'left' : 'center'}}><span style={{fontWeight: 'bold', color: '#005fa4'}}>Hygiene Merit </span>is a company with more than 12 years of experience in the cleaning industry,
              with presence in the USA and Mexico, providing highly specialized services in the
              medical area. Hygiene Merit promises to offer hospital
              grade cleaning and disinfection
              services with state of the art
              technology to create clean and
              safe environments from invisible
              threats.
            </text>
            <text style={{marginTop: 25, fontSize: 20, textAlign: mobile ? 'left' : 'center'}}>
              Our <span style={{fontWeight: 'bold', color: '#005fa4'}}>MISSION </span>
              is to protect lives against invisible threats.
            </text>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 15}}>
              <h2 style={{fontWeight: 'bold', color: '#005fa4'}}>Our Values</h2>

              <div style={{display: 'flex', alignItems: 'center', width: 300}}>
                <img src={god} style={{width: 50, marginRight: 15}}/>
                <text style={{fontSize: 20}}>God First</text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 30, width: 300}}>
                <img src={expectations} style={{width: 50, marginRight: 15}} />
                <text style={{fontSize: 20}}>Exceeding Expectations</text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 30, width: 300}}>
                <img src={commitment} style={{width: 50, marginRight: 15}} />
                <text style={{fontSize: 20}}>Commitment</text>
              </div>
            </div>
          </div>

          :

          <div style={{display: 'flex', justifyContent: 'space-around', width: '75%'}}>
            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
              <h2 style={{color: '#005fa4'}}>About Us</h2>
              <text style={{fontSize: 20, textAlign: 'left'}}><span style={{fontWeight: 'bold', color: '#005fa4'}}>Hygiene Merit </span>is a company with more than 12 years of experience in the cleaning industry,
                with presence in the USA and Mexico, providing highly specialized services in the
                medical area. Hygiene Merit promises to offer hospital
                grade cleaning and disinfection
                services with state of the art
                technology to create clean and
                safe environments from invisible
                threats.
              </text>
              <text style={{marginTop: 25, fontSize: 20, textAlign: 'left'}}>
                Our <span style={{fontWeight: 'bold', color: '#005fa4'}}>MISSION </span>
                is to protect lives against invisible threats.
              </text>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 250}}>
              <h2 style={{fontWeight: 'bold', color: '#005fa4'}}>Our Values</h2>

              <div style={{display: 'flex', alignItems: 'center', width: 300}}>
                <img src={god} style={{width: 50, marginRight: 15}}/>
                <text style={{fontSize: 20}}>God First</text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 30, width: 300}}>
                <img src={expectations} style={{width: 50, marginRight: 15}} />
                <text style={{fontSize: 20}}>Exceeding Expectations</text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 30, width: 300}}>
                <img src={commitment} style={{width: 50, marginRight: 15}} />
                <text style={{fontSize: 20}}>Commitment</text>
              </div>
            </div>
          </div>
        }
      </div>

      <div style={{backgroundColor: '#005fa4'}}>
        <Faq mobile={mobile}/>
      </div>

      <div style={{display: 'flex', justifyContent: 'center', backgroundColor: '#37cefd'}}>
        <Button href={"http://www.hygienemerit.com"} style={{color: 'white', margin: 5}}
                startIcon={<InfoIcon />}
        >
          learn More
        </Button>
        <Button href={"http://www.hygienemerit.com/contact-us/"} style={{color: 'white', margin: 5}}
                startIcon={<ShareIcon />}
        >
          Contact Us
        </Button>
      </div>

      <div style={styles.copyright}>
        <Copyright />
      </div>

    </div>
  );
}

export default App;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
}));

const styles = {
    banner: {
        //backgroundImage: `url(${banner})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: "100%",
        height: '30vw',
        //maxHeight: 350,
        minHeight: 300
    },
    banner2: {
        // backgroundImage: `url(${covid})`,
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        width: "100%",
        height: '30vw',
        maxHeight: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#005fa4',
    },
    banner3: {
        backgroundImage: `url(${bluecovid})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: "100%",
        height: '25vw',
        maxHeight: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    image:{
        objectFit: 'cover',
        width: 250,
        height: '100%',
        maxHeight: 250
    },
    background: {
        //backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    copyright: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f6f6f6'
    }
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://hygienemerit.com/">
                HygieneMerit
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
