import React, {useState} from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import '../App.css'
import {animated, useSpring} from "react-spring";

const questions = [
  {
    q: 'How can I be sure my commercial cleaning company is using a disinfectant that is effective against COVID-19?',
    a: 'Their disinfecting chemicals should be included on the EPA’s List N. This is a list of registered ' +
      'disinfectants that have been proven effective against Human Coronavirus.  The EPA has not established any ' +
      'effectiveness protocols for surface disinfectants.  However, most in the scientific community believe that' +
      ' when the EPA establishes protocols, the results will show that if surface disinfectants are effective for ' +
      'other Coronaviruses, they will also be effective for COVID-19.  Here is a link to List N: ' +
      'https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2-covid-19#filter_col1'
  },
  {
    q: 'I hear things like electrostatic spraying and fogging.  Are these effective ways to disinfect my facility?',
    a: 'Yes**.  Electrostatic sprayers deliver charged droplets of disinfectant that are attracted to surfaces, ' +
      'including the back sides and crevices of surfaces, for wrap-around disinfection coverage. Fogging, or misting, ' +
      'systems deliver very small droplets of disinfectant on surfaces. Based on the direction of spray and the ' +
      'effect of gravity, fogging may result in uneven coverage.  If you have a choice, electrostatic sprayers are ' +
      'a better option.  **HOWEVER, neither of these methods are effective without proper CLEANING of surfaces first.  ' +
      'Because the coronavirus is so small, dust and other surface debris act as a shield to disinfectants that ' +
      'are simply sprayed on a surface.'
  },
  {
    q: 'I am reading and hearing all kinds of different things about cleaning and disinfecting.  I just want to ' +
      'know how to keep my facility as safe as possible.  What do you recommend?',
    a: 'First, there is no method or process of cleaning and disinfecting that can guarantee that your facility will ' +
      'avoid a COVID contamination.  However, there are cleaning and disinfecting measures that can help to lessen the ' +
      'probability of a COVID contamination.  These measures are very similar to guidelines during any type of virus ' +
      'season.  '
  },
  {
    q: 'My cleaning team told me that a disinfectant had to “dwell” on a surface for 10 minutes before it is okay to ' +
      'wipe off or touch.  What is “dwell time” and is this true?',
    a: 'Dwell time, or contact time, is the amount of time disinfectants need to remain wet on surfaces to properly ' +
      'disinfect.  Each product has specific dwell time requirements that can be found on the product label.  While it ' +
      'is true that some products have a dwell time of 10 minutes, other products have dwell times in the 3-5-minute range.'
  },
  {
    q: 'Is there any kind of product or application process that will kill COVID-19 for an extended period?',
    a: 'No.  While there are products (antimicrobial coatings) on the market making claims about “keeping surfaces ' +
      'clear of human coronavirus for up to 90 days with just one application”, there is no evidence that these ' +
      'products or applications actually work.  Click on this link for more information: ' +
      'https://healthybuilding.net/reports/22-covid-19-statement-understanding-antimicrobial-ingredients-in-building-materials'
  },
  {
    q: 'Once a surface is disinfected, how long will it remain free from contamination?',
    a: 'Unfortunately, a surface can be re-contaminated immediately if someone with a virus touches the surface.  ' +
      'As mentioned in the previous question, there is no product or process that, when applied, will kill any ' +
      'coronavirus for an extended period.  However, the good news is that COVID-19 is thought to spread mainly from ' +
      'person to person, mainly through respiratory droplets produced when an infected person coughs, sneezes, or ' +
      'talks.  So, if an infected person touches a surface, yet doesn’t sneeze or cough, on it, the surface should ' +
      'not be contaminated.'
  },
]



export default function Faq(props) {
  const [active, setActive] = useState(-1)

  function expand(i) {
    if (i === active) {
      setActive(-1)
    } else {
      setActive(i)
    }
  }

  const expandProps = [
    useSpring({top: active===0 ? 0 : -100}),
    useSpring({top: active===1 ? 0 : -100}),
    useSpring({top: active===2 ? 0 : -100}),
    useSpring({top: active===3 ? 0 : -100}),
    useSpring({top: active===4 ? 0 : -100}),
    useSpring({top: active===5 ? 0 : -100}),
  ]

  return (
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h2 style={{color: 'white', marginTop: 50}}>FAQ</h2>
      <div style={{padding: '0px 0px 50px', width: props.mobile ? '90%' : '75%'}}>
        {questions.map((question, index) => (
          <div style={{marginBottom: 0, overflow: 'hidden'}}>
            <div style={{padding: 15, border: '0px solid rgba(255, 255, 255, 0.25)', display: 'flex',
              cursor: 'pointer', backgroundColor: '#005fa4', zIndex: 12, position: 'relative'}}
                 onClick={() => expand(index)}
                 className={'noText'}
            >
              <text style={{fontWeight: 'bold', color: '#fff'}}>{question.q}</text>
              <div style={{flex: 1}}/>
              <div
                style={
                  { backgroundColor: active === index ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                    width: 25, height: 25, borderRadius: 30, display: 'flex', justifyContent: 'center',
                    alignItems: 'center', marginLeft: props.mobile ? 15 : 100
                  }}
              >
                { active === index ?
                  <ExpandLessIcon style={{margin: '0px 15px 0px 15px', color: '#005fa4'}}/> :
                  <ExpandMoreIcon style={{margin: '0px 15px 0px 15px', color: '#005fa4'}}/>
                }
              </div>
            </div>
            { active === index ?
              <animated.div style={{padding: 15, backgroundColor: 'rgba(246,246,246,0.25)', position: 'relative', ...expandProps[index]}}>
                <text style={{color: 'white'}}>{question.a}</text>
              </animated.div> : null
            }
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  main: {

  }
}
