import React from 'react'
import banner from "../assets/bannerYT.png";
import sprayer from '../assets/sprayers.jpg'
import uvbot from '../assets/uvbot.png'
import covid from '../assets/bluecovid.png'
import atp from '../assets/hygiena.jpg'

export default function Methods({mobile}) {
  return(
    <div style={styles.root}>

      <h3 style={{textAlign: 'center', color: '#16b6e9'}}>METHODS</h3>

      <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: !mobile ? 25 : 0}}>
          <img src={sprayer} alt={''} style={styles.image}/>
          <div style={styles.descriptionContainer}>
            <text style={styles.title}>Electrostatic Spraying</text>
            <text style={styles.description}>This method helps disinfect an area by giving an electric
              charge to the liquid to quickly and evenly coat a surface. This is required for offices
              due to multitude of touch points.</text>
          </div>
        </div>

        <div style={{height: 50}}/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: !mobile ? 25 : 0}}>
          <img src={uvbot} alt={''} style={styles.image}/>
          <div style={styles.descriptionContainer}>
            <text style={styles.title}>Ultraviolet Germicidal Irradiation</text>
            <text style={styles.description}>This method of disinfection uses a shortwave ray of light that
              is proven to effectively damage the DNA of germs so as to eliminate their presence.</text>
          </div>
        </div>

        <div style={{height: 50}}/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: !mobile ? 25 : 0}}>
          <img src={atp} alt={''} style={styles.image}/>
          <div style={styles.descriptionContainer}>
            <text style={styles.title}>ATP Surface Testing</text>
            <text style={styles.description}>Adenosine Triphosphate, or ATP, is the energy molecule found in
              all living things, making it a perfect indicator when trying to determine if a surface is clean
              or not.</text>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#005fa4',
    margin: 20,
    marginBottom: 50
  },
  image: {
    width: '60vw',
    maxHeight: 400,
    maxWidth: 400,
    objectFit: 'cover',
  },
  descriptionContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bold',
    margin: 5,
    textAlign: 'center'
  },
  description: {
    textAlign: 'center'
  },
  banner: {
    backgroundImage: `url(${covid})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: "100%",
    height: '30vw',
    maxHeight: 250
  },
}
